// extracted by mini-css-extract-plugin
export var date = "index-module--date--ee8e0";
export var intro = "index-module--intro--0b876";
export var lightningTalk = "index-module--lightningTalk--b6050";
export var list = "index-module--list--fb407";
export var listItem = "index-module--listItem--06e6d";
export var listItemDescription = "index-module--listItemDescription--f3875";
export var listItemLink = "index-module--listItemLink--02c9e";
export var primaryButtonLarge = "index-module--primaryButtonLarge--efcea";
export var specialDesc = "index-module--specialDesc--7a919";
export var sponsorLogo = "index-module--sponsorLogo--bb11c";
export var tableDesc = "index-module--tableDesc--90229";
export var textCenter = "index-module--textCenter--4ece3";
export var timeTable = "index-module--timeTable--9186f";
export var tweet = "index-module--tweet--f809c";